import Settings from "./Settings";
import {Shop} from "./Shop";

export const INSTANT_WIN_EVENT_TYPE = 'INSTANT_WIN_EVENT_TYPE'
export const SCANNED_COUPONS_EVENT_TYPE = 'SCANNED_COUPONS_EVENT_TYPE'
export const SMS_EVENT_TYPE = 'SMS_EVENT_TYPE'

export class Event {
    name: string;
    slug: string;
    gallery: string;
    city: string;
    zipCode: string;
    street: string;
    number: string;

    panelImageUrl: string;
    statuteUrl: string;
    shops: Shop[];
    settings: Settings;


    constructor(name: string, slug: string, gallery: string, city: string, zipCode: string, street: string, number: string, panelImageUrl: string, statuteUrl: string, shops: Shop[], settings: Settings) {
        this.name = name;
        this.slug = slug;
        this.gallery = gallery;
        this.city = city;
        this.zipCode = zipCode;
        this.street = street;
        this.number = number;
        this.panelImageUrl = panelImageUrl;
        this.statuteUrl = statuteUrl;
        this.shops = shops;
        this.settings = settings;
    }

    public static fromApiResponse(data: any) {
        return new Event(
            data?.name ? data.name : '',
            data?.slug ? data.slug : '',
            data?.gallery_name ? data.gallery_name : '',
            data?.city ? data.city : '',
            data?.zip_code ? data.zip_code : '',
            data?.street ? data.street : '',
            data?.number ? data.number : '',
            data?.register_panel_image_url,
            data?.event_statute,
            data?.shops && data.shops.length > 0 ? data.shops.map((s: any) => Shop.fromApiResponse(s)) : [],
            Settings.fromApiResponse(data?.settings),
        );
    }
}