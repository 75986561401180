import {FC, useContext, useState} from "react";
import AuthContext from "../context/auth-context";
import CentralLayout from "../layout/CentralLayout";
import bgImage from "../assets/bg-sign-in-basic.jpeg";
import {Box, Button, Card, TextField, Typography, useTheme} from "@mui/material";

const Login: FC = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const {login} = useContext(AuthContext);
    const {palette} = useTheme();

    const handleLogin = () => {
        login(email, password);
    }

    const handleEmailChange = (event: any) => {
        setEmail(event.target.value);
    }

    const handlePasswordChange = (event: any) => {
        setPassword(event.target.value);
    }

    return (
        <CentralLayout image={bgImage}>
            <Card
                sx={{
                    borderRadius: "20px",
                    display: "flex",
                    flexDirection: "column",
                    overflow: "visible"
                }}
            >
                <Box
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                    sx={{
                        background: `linear-gradient(195deg, ${palette.warning.main}, ${palette.warning.light})`,
                        borderRadius: "20px",
                        zIndex: 10,
                        boxShadow: "0rem 0.25rem 1.25rem 0rem rgb(0, 0, 0, 0.14), 0rem 0.4375rem 0.625rem -0.3125rem rgb(189, 163, 58, 0.4)"
                    }}
                >
                    <Typography variant="h5" fontWeight="700" color="white" mt={1}>
                        Zaloguj się
                    </Typography>
                </Box>
                <Box pt={4} pb={3} px={3}>
                    <Box component="form" role="form">
                        <Box mb={2}>
                            <TextField type="email" label="Email" value={email} onChange={handleEmailChange} fullWidth/>
                        </Box>
                        <Box mb={2}>
                            <TextField type="password" label="Password" value={password} onChange={handlePasswordChange}
                                       fullWidth/>
                        </Box>
                        <Box mt={4} mb={1}>
                            <Button
                                fullWidth
                                onClick={handleLogin}
                                sx={{
                                    background: `linear-gradient(195deg, ${palette.warning.main}, ${palette.warning.light})`,
                                    fontWeight: 700,
                                    color: "white"
                                }}
                            >
                                Zaloguj
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Card>
        </CentralLayout>
    );
}

export default Login;
