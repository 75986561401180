import React, {FC, useContext, useState} from "react";
import {CircularProgress, Grid,} from "@mui/material";
import AgreementSection, {AGREEMENTS_ERROR_MSG} from './AgreementSection';
import './form.css';
import AuthContext from "../context/auth-context";
import AlertContext from "../context/alert-context";
import {ELEMENTS_WIDTH} from "../style/ui-constants";
import IdentifierField from "./IdentifierField";
import FormContext, {Field, FormContextType, ReceiptType} from "../context/form-context";
import SettingsContext from "../context/settings-context";
import FirstNameField from "./FirstNameField";
import Settings, {EMAIL_ID_FIELD, LOYALTY_CARD_NUMBER_ID_FIELD, PHONE_ID_FIELD} from "../model/Settings";
import EmailField from "./EmailField";
import PhoneField from "./PhoneField";
import LoyaltyCardNumberField from "./LoyaltyCardNumberField";
import LastNameField from "./LastNameField";
import ZipCodeField from "./ZipCodeField";
import ReceiptsFields, {
    AMOUNT_ERROR_MSG,
    DATE_ERROR_MSG,
    MIN_SUM_ERROR_MSG,
    NUMBER_ERROR_MSG,
    SHOP_ERROR_MSG
} from "./ReceiptsFields";
import {registerNewReceipts, registerParticipant} from "../api/participant-api";
import {Participant} from "../model/Participant";
import {INSTANT_WIN_EVENT_TYPE, SCANNED_COUPONS_EVENT_TYPE} from "../model/Event";
import CouponsFields, {COUPON_ERROR_MSG, RECEIPT_GRANT} from "./CouponsFields";
import ExtraCoupons from "./ExtraCoupons";

const Form: FC = () => {
    const {user} = useContext(AuthContext);
    const formCtx = useContext(FormContext);
    const {formExpanded} = formCtx;
    const {event: {settings, slug}} = useContext(SettingsContext);
    const [isSending, setIsSending] = useState(false);
    const alertCtx = useContext(AlertContext);

    const validateForm = (settings: Settings, ctx: FormContextType) => {
        ctx.setValidated(true);

        let validationMsg = null;
        if (settings.phoneRequired || settings.identifier === PHONE_ID_FIELD) {
            validationMsg = ctx.phone.error
        }
        if ((settings.emailRequired || settings.identifier === EMAIL_ID_FIELD) && !validationMsg) {
            validationMsg = ctx.email.error
        }
        if ((settings.loyaltyCardNumberRequired || settings.identifier === LOYALTY_CARD_NUMBER_ID_FIELD) && !validationMsg) {
            validationMsg = ctx.loyaltyCardNumber.error
        }
        if (settings.firstNameRequired && !validationMsg) {
            validationMsg = ctx.firstName.error
        }
        if (settings.lastNameRequired && !validationMsg) {
            validationMsg = ctx.lastName.error
        }
        if (settings.zipCodeRequired && !validationMsg) {
            validationMsg = ctx.zipCode.error
        }
        //CHECK RECEIPTS DATA
        const receiptsValidationResults = validateReceipts(formCtx)
        validationMsg = !!receiptsValidationResults && !validationMsg ? receiptsValidationResults : validationMsg
        if (!formCtx.agreements.reduce((a, b) => a && b) && !validationMsg) {
            validationMsg = AGREEMENTS_ERROR_MSG
        }
        // CHECK MIN SUM
        const minSumValidationResult = validateMinAmount(settings, formCtx.receipts)
        validationMsg = !!minSumValidationResult && !validationMsg ? minSumValidationResult : validationMsg
        // CHECK COUPONS
        const couponsValidationResult = validateCoupons(settings, formCtx.coupons)
        validationMsg = !!couponsValidationResult && !validationMsg ? couponsValidationResult : validationMsg
        // CHECK EXTRA COUPONS
        const extraCouponsValidationResult = validateCoupons(settings, formCtx.extraCoupons)
        validationMsg = !!extraCouponsValidationResult && !validationMsg ? extraCouponsValidationResult : validationMsg
        return validationMsg;
    }

    const validateReceipts = (ctx: FormContextType) => {
        let msg = null;
        const receiptsCopy = ctx.receipts.map((r: ReceiptType, index: number) => {
            let copy = {...r}
            if (index === 0 || copy.date || copy.number || copy.amount || copy.shopName) {
                if (!copy.date) {
                    copy = {...copy, dateError: DATE_ERROR_MSG}
                    msg = DATE_ERROR_MSG
                }
                if (!copy.number) {
                    copy = {...copy, numberError: NUMBER_ERROR_MSG}
                    msg = NUMBER_ERROR_MSG
                }
                if (isNaN(Number(copy.amount)) || Number(copy.amount) === 0) {
                    copy = {...copy, amountError: AMOUNT_ERROR_MSG}
                    msg = AMOUNT_ERROR_MSG
                }
                if (!copy.shopName) {
                    copy = {...copy, shopNameError: SHOP_ERROR_MSG}
                    msg = SHOP_ERROR_MSG
                }
                return copy
            } else {
                return {...copy, dateError: null, numberError: null, amountError: null, shopNameError: null}
            }
        })
        formCtx.setReceipts(receiptsCopy)
        return msg
    }

    const validateMinAmount = (settings: Settings, receipts: ReceiptType[]) => {
        const sum = receipts.map((e: ReceiptType) => Number(e.amount)).reduce((a: number, b: number) => a + b, 0)
        if (sum < settings.chanceStep) {
            return MIN_SUM_ERROR_MSG;
        } else {
            return null;
        }
    }

    const validateCoupons = (settings: Settings, coupons: Field[]) => {
        console.log(coupons)
        if (settings.eventType === SCANNED_COUPONS_EVENT_TYPE) {
            const codes = coupons.map(c => c.value)
            if (coupons.filter(c => !c.value).length > 0 ||
                coupons.filter(c => !!c.error).length > 0 ||
                codes.filter((item, index) => codes.indexOf(item) !== index && codes.indexOf(item) != -1).length > 0
            ) {
                return COUPON_ERROR_MSG;
            }
        }
    }

    const actionSend = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setIsSending(true);
        const msg = validateForm(settings, formCtx)
        if (!!msg) {
            setIsSending(false);
            alertCtx.setAlert({title: msg, severity: "warning"});
            return;
        }
        if (!formCtx.participantExists) {
            registerParticipant(
                user?.token,
                Participant.toRequest(formCtx),
                slug,
                (participant) => {
                    formCtx.setParticipantExists(true)
                    formCtx.setParticipant(participant)
                    alertCtx.setAlert({title: "Uczestnik został poprawnie zarejestrowany", severity:"success"})
                },
                (error) => {
                    console.log(error)
                    alertCtx.setAlert({title: error})
                }
            )
        } else {
            const coupons = settings.eventType === SCANNED_COUPONS_EVENT_TYPE ? Participant.couponsToRequest(formCtx.coupons, formCtx.extraCoupons) : []
            registerNewReceipts(
                user?.token,
                {
                    receipts: Participant.receiptsToRequest(formCtx.receipts),
                    coupons: coupons,
                    extra_chances: formCtx.extraChances + formCtx.newExtraChances
                },
                formCtx.id,
                slug,
                (participant) => {
                    formCtx.setParticipantExists(true)
                    alertCtx.setAlert({title: "Pargony zostały poprawnie zarejestrowane", severity:"success"})
                },
                (error) => {
                    console.log(error)
                    alertCtx.setAlert({title: error})
                }
            )
        }
        setIsSending(false);
    }

    return (
        <>
            <IdentifierField/>
            {formExpanded && <>
                {settings.identifier !== EMAIL_ID_FIELD && settings.emailRequired ? <EmailField/> : <></>}
                {settings.identifier !== PHONE_ID_FIELD && settings.phoneRequired ? <PhoneField/> : <></>}
                {(settings.identifier !== LOYALTY_CARD_NUMBER_ID_FIELD && settings.loyaltyCardNumberRequired) ?
                    <LoyaltyCardNumberField/> : <></>}
                <FirstNameField/>
                <LastNameField/>
                <ZipCodeField/>
                <ReceiptsFields/>
                <CouponsFields/>
                <ExtraCoupons/>
                <AgreementSection/>
                {!(settings.eventType === INSTANT_WIN_EVENT_TYPE && formCtx.participantExists) &&
                    <Grid item sx={{display: "flex", justifyContent: "center"}} width={ELEMENTS_WIDTH} mb={4}>
                        {isSending && <CircularProgress/>}
                        {!isSending &&
                            <input className={'button'} type="submit"
                                   value="Zarejestruj"
                                   onClick={actionSend}/>}
                    </Grid>}
            </>}
        </>
    )
}

export default Form;
