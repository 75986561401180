export class User {
    id: number;
    name: string;
    email: string;
    token: string;
    role: {
        id: number;
        name: string;
        priority: number;
    };
    access: string[]

    constructor(id: number, name: string, email: string, token: string, role: { id: number; name: string; priority: number }, access: string[]) {
        this.id = id;
        this.name = name;
        this.email = email;
        this.token = token;
        this.role = role;
        this.access = access;
    }

    public static fromApiResponse(response: any) {
        const role = {
            id: response.user.role.id,
            name: response.user.role.name,
            priority: response.user.role.priority,
        }
        const access = response.user.access[0]?.slug;
        return new User(
            response.user.id,
            response.user.name,
            response.user.email,
            response.token,
            role,
            access
        )
    }
}


