import {PrizeType} from "./Prize";
import {Field, FormContextType, ReceiptType} from "../context/form-context";
import {ADDITIONAL_GRANT, RECEIPT_GRANT} from "../components/CouponsFields";

export type Receipt = {
    id?: number;
    amount: number | null;
    shopName: string | null;
    date: Date | null;
    number: string | null;
}

export class Participant {
    id: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    loyaltyCardNumber: string;
    zipCode: string;
    created: Date | null;
    receipts: Receipt[] | null;
    prizes: PrizeType[] | null;
    extraChances: number;

    // coupons: Coupon[] | null;

    constructor(id: string, firstName: string, lastName: string, phone: string, email: string, loyaltyCardNumber: string, zipCode: string, created: Date | null, receipts: Receipt[] | null, prizes: PrizeType[] | null, extraChances: number) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.phone = phone;
        this.email = email;
        this.loyaltyCardNumber = loyaltyCardNumber;
        this.zipCode = zipCode;
        this.created = created;
        this.receipts = receipts;
        this.prizes = prizes;
        this.extraChances = extraChances;
    }

    public static fromApiResponse(response: any) {
        return new Participant(
            response["id"],
            response["first_name"],
            response["last_name"],
            response["phone"],
            response["email"],
            response["loyalty_card_number"],
            response["zip_code"],
            new Date(response["created_at"]),
            response["receipts"] && response["receipts"].map((receipt: any) => {
                return {
                    id: receipt["id"],
                    amount: receipt["amount"],
                    shopName: receipt["shop"]["name"],
                    number: receipt["receipt_number"],
                    date: new Date(receipt["receipt_date"]),
                }
            }),
            response["prizes"] && response["prizes"].map((prize: any) => {
                return {
                    id: prize["id"],
                    refNum: prize["ref_num"],
                    name: prize["name"],
                    report: prize["media"] && prize["media"].length > 0 && prize["media"][0]["uuid"],
                    gallery: prize["event"]["gallery_name"],
                    wonDate: prize["won_date"] && new Date(prize["won_date"]),
                    collectedDate: prize["collected_date"] && new Date(prize["collected_date"]),
                }
            }),
            response["extra_chances"]
            // response["coupons"] && response["coupons"].map((coupon) => {
            //     return {
            //         id: coupon["id"],
            //         chances: coupon["chances"],
            //         code: coupon["code"],
            //         grantedBy: coupon["granted_by"],
            //         receiptsIds: coupon["receipts"] && coupon["receipts"].map(r => r.receipt_id).join(", "),
            //         createdAt: new Date(coupon["created_at"]),
            //         updatedAt: new Date(coupon["updated_at"]),
            //     }
            // })
        )
    }

    public static toRequest(ctx: FormContextType) {
        return {
            first_name: ctx.firstName.value,
            last_name: ctx.lastName.value,
            phone: ctx.phone.value,
            email: ctx.email.value,
            zip_code: ctx.zipCode.value,
            loyalty_card_number: ctx.loyaltyCardNumber.value,
            receipts: this.receiptsToRequest(ctx.receipts),
            coupons: this.couponsToRequest(ctx.coupons, ctx.extraCoupons),
            extra_chances: ctx.extraChances + ctx.newExtraChances
        }
    }

    public static receiptsToRequest(receipts: ReceiptType[]) {
        return receipts.filter(r => r.amount || r.shopName || r.date || r.number).map((r: ReceiptType) => {
            return {
                amount: Number(r.amount),
                shop_name: r.shopName,
                receipt_date: r.date,
                receipt_number: r.number
            }
        })
    }

    public static couponsToRequest(coupons: Field[], extraCoupons: Field[]) {
        const receiptsCoupons =  coupons.map((c: Field) => {
            return {
                code: c.value,
                chances: 1,
                granted_by: RECEIPT_GRANT,
            }
        })
        const exCoupons =  extraCoupons.map((c: Field) => {
            return {
                code: c.value,
                chances: 1,
                granted_by: ADDITIONAL_GRANT,
            }
        })
        return [...receiptsCoupons, ...exCoupons]
    }
}
