import React, {useContext, useEffect} from 'react';
import {CssBaseline, ThemeProvider} from "@mui/material";
import {Navigate, Route, Routes} from "react-router-dom";
import AuthContext from "./context/auth-context";
import theme from "./style/theme";
import Login from "./pages/Login";
import FormPage from "./pages/FormPage";

function App() {
    const { user} = useContext(AuthContext);

    useEffect(() => {
        document.title = 'Rejestracja'
    },[user])

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Routes>
                <Route path={"/login"} element={<Login/>} key={"login"}/>
                {user?.access && <Route path={"/" + user?.access} element={<FormPage/>} key={"form"}/>}
                {user?.access && <Route path={"*"} element={<Navigate to={"/" + user?.access}/>} key={"redirect"}/>}
                {!user?.access && <Route path={"*"} element={<Navigate to={"/login"}/>} key={"redirect"}/>}
            </Routes>
        </ThemeProvider>
    );
}

export default App;
