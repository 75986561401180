import React, {FC, useContext, useEffect, useState} from "react";
import {Box, Button, Grid, Modal, styled, TextField, Typography} from "@mui/material";
import {ELEMENTS_WIDTH} from "../style/ui-constants";
import SettingsContext from "../context/settings-context";
import FormContext, {Field} from "../context/form-context";
import {SCANNED_COUPONS_EVENT_TYPE} from "../model/Event";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import barcode from "../assets/images/barcode.png";


export const ScannerButton = styled(Button)({
    width: '60px',
    borderRadius: '0.375rem',
    border: '1px solid rgba(118, 118, 118, 0.4) !important',
    padding: '0',
    marginLeft: '10px'
})

const ModalBox = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: '405px',
    transform: 'translate(-50%, -50%)',
    border: '1px solid #000',
    boxShadow: 24,
    backgroundColor: '#fff',
}

export const COUPON_ERROR_MSG = "Niepoprawny kupon";
export const RECEIPT_GRANT = 'receipt';
export const ADDITIONAL_GRANT = 'additional';

const CouponsFields: FC = () => {
    const {event: {settings, slug}} = useContext(SettingsContext);
    const {receipts, coupons, setCoupon, validated, setCoupons, extraCoupons} = useContext(FormContext);
    const [receiptsSum, setReceiptsSum] = useState(0);
    const [index, setIndex] = useState<number>(-1);
    const [display, setDisplay] = useState(false);
    const [couponsNumber, setCouponsNumber] = useState(0);

    useEffect(() => {
        const receiptsSum = receipts.map(r => Number(r.amount)).reduce((a, b) => a + b)
        setReceiptsSum(receiptsSum)
    })


    useEffect(() => {
        const display = settings.eventType === SCANNED_COUPONS_EVENT_TYPE && receiptsSum >= settings.chanceStep
        const chances = Math.floor(receiptsSum / settings.chanceStep)
        const couponsNumber = chances <= settings.maxChances ? chances : settings.maxChances
        setDisplay(display);
        setCouponsNumber(couponsNumber);
        setCoupons(Array(couponsNumber).fill({value: '', error: COUPON_ERROR_MSG}));
    }, [receiptsSum]);

    const changeHandler = (index: number, value: string) => {
        const trimmed = value.trim().replace(/[^0-9]/g, '');
        if (trimmed.length != 8 || checkOtherCoupons(value, coupons) || checkOtherCoupons(value, extraCoupons, false)) {
            setCoupon(index, {value: trimmed, error: COUPON_ERROR_MSG});
        } else {
            setCoupon(index, {value: trimmed, error: undefined});
        }
    }

    const checkOtherCoupons = (value: string, coupons: Field[], sameCollection: boolean = true) => {
        const codes = coupons.map(c => c.value)
        return codes.filter((item, index) => (sameCollection ? codes.indexOf(value) !== index : true) && codes.indexOf(value) != -1).length > 0;
    }

    return (
        <>{display && <>
            <Grid item container width={ELEMENTS_WIDTH}>
                <Typography variant={"h6"}>Kupony</Typography>
            </Grid>
            {Array(couponsNumber).fill(null).map((e: any, i: number) => (
                <Grid container item justifyContent="space-between" width={'500px'}>
                    <TextField
                        sx={{width: '420px'}}
                        onChange={(event) => changeHandler(i, event.target.value)}
                        value={coupons[i].value}
                        label={"Kupon " + (i + 1)}
                        variant="outlined"
                        helperText={validated && coupons[i].error}
                        error={!!coupons[i].error && validated}
                        required
                    />
                    <ScannerButton onClick={() => setIndex(i)}>
                        <Box component="img" sx={{width: '44px'}} src={barcode}/>
                    </ScannerButton>
                </Grid>))}
            <Modal
                open={index >= 0}
                onClose={() => setIndex(-1)}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={ModalBox}>
                    <BarcodeScannerComponent
                        width={400}
                        height={400}
                        facingMode={"environment"}
                        onUpdate={(err, result) => {
                            if (result) {
                                changeHandler(index, result['text'])
                                setIndex(-1);
                            } else return coupons[index].value
                        }}
                        stopStream={!!index}
                    />
                </Box>
            </Modal>
        </>
        }
        </>
    )
}

export default CouponsFields;
