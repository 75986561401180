import {Grid, TextField} from "@mui/material";
import {ELEMENTS_WIDTH} from "../style/ui-constants";
import React, {useContext, useEffect} from "react";
import SettingsContext from "../context/settings-context";
import FormContext from "../context/form-context";

const LAST_NAME_ERROR_MSG = "Niepoprawna wartość dla pola 'nazwisko'";

const LastNameField = () => {
    const {event: {settings}} = useContext(SettingsContext);
    const {lastName, setLastName, participantExists, validated} = useContext(FormContext);

    useEffect(() => {
        setLastName({value: lastName.value, error: !lastName.value ? LAST_NAME_ERROR_MSG : undefined});
    }, [])
    const changeHandler = (event: { target: { value: any } }) => {
        const value = event.target.value.trim();
        if (value.length <= 1) {
            setLastName({value, error: LAST_NAME_ERROR_MSG});
        } else {
            setLastName({value, error: undefined});
        }
    }

    return <>
        {settings.lastNameRequired ? <Grid item width={ELEMENTS_WIDTH}>
            <TextField
                fullWidth
                onChange={changeHandler}
                value={lastName.value}
                label="Nazwisko"
                variant="outlined"
                helperText={validated && lastName.error}
                error={!!lastName.error && validated}
                disabled={participantExists}
                required
            />
        </Grid> : <></>}
    </>
}

export default LastNameField;