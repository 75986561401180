import SettingsContext from "../context/settings-context";
import React, {useContext, useEffect} from "react";
import {Autocomplete, Grid, TextField, Typography} from "@mui/material";
import {ELEMENTS_WIDTH} from "../style/ui-constants";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import FormContext, {ReceiptType} from "../context/form-context";
import {INSTANT_WIN_EVENT_TYPE} from "../model/Event";

export const AMOUNT_ERROR_MSG = "Niepoprawna kwota"
export const DATE_ERROR_MSG = "Niepoprawna data"
export const SHOP_ERROR_MSG = "Niepoprawna nazwa sklepu"
export const NUMBER_ERROR_MSG = "Niepoprawny numer paragonu"
export const MIN_SUM_ERROR_MSG = "Kwota na paragonach jest niewystarczająca"

const ReceiptsFields = () => {
    const {event, event: {settings}} = useContext(SettingsContext);
    const {participantExists, setReceipt, receipts, setReceiptsNumber, validated} = useContext(FormContext);

    const disabled = participantExists && (settings.eventType === INSTANT_WIN_EVENT_TYPE)
    const shopNames = event.shops.map(s => s.name)

    useEffect(() => {
        setReceiptsNumber(settings.maxReceipts)
    }, [])

    const changeHandler = (index: number, receipt: ReceiptType) => {
        const errorKey = Object.keys(receipt)[0] + "Error";
        // @ts-ignore
        receipt[errorKey] = null
        setReceipt(index, receipt)
    }

    const changeAmountHandler = (index: number, value: string) => {
        let amount = value.replace(/[^0-9,.]/g, '').replace(/,/g, '.').trim()
        setReceipt(index, {amount, amountError: null})
    }

    const sumReceipts = (receipts: ReceiptType[]) => {
        return receipts.map((e: ReceiptType) => Number(e.amount)).reduce((a: number, b: number) => a + b, 0)
    }

    return <>
        {receipts.map((e: any, index: number) =>
            <>
                <Grid item container width={ELEMENTS_WIDTH}>
                    <Typography variant={"h6"}>Paragon {index + 1}</Typography>
                </Grid>
                <Grid item width={ELEMENTS_WIDTH} display={'flex'} justifyContent={'space-between'}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Data paragonu"
                            value={receipts[index].date}
                            disabled={disabled}
                            onChange={(value) => {
                                changeHandler(index, {date: value})
                            }}
                            renderInput={(params) => <TextField {...params}
                                                                style={{width: '48%'}}
                                                                error={!!receipts[index].dateError && validated}
                                                                required/>}
                        />
                    </LocalizationProvider>
                    <TextField
                        onChange={(event) => changeHandler(index, {number: event.target.value})}
                        value={receipts[index].number}
                        label="Numer paragonu"
                        variant="outlined"
                        style={{width: '48%'}}
                        disabled={disabled}
                        helperText={validated && receipts[index].numberError}
                        error={!!receipts[index].numberError && validated}
                        required
                    />
                </Grid>
                <Grid item width={ELEMENTS_WIDTH} display={'flex'} justifyContent={'space-between'}>
                    <Autocomplete
                        disablePortal
                        value={receipts[index].shopName}
                        disabled={disabled}
                        onChange={(event: any, newValue: string | null) => {
                            changeHandler(index, {shopName: newValue});
                        }}
                        onInputChange={(event, newInputValue) => {
                            changeHandler(index, {shopName: newInputValue});
                        }}
                        id="combo-box-demo"
                        options={shopNames}
                        renderInput={(params) => <TextField {...params}
                                                            value={receipts[index].shopName}
                                                            required
                                                            error={!!receipts[index].shopNameError && validated}
                                                            helperText={validated && receipts[index].shopNameError}
                                                            label="Sklep"/>}
                        style={{width: '48%'}}
                    />
                    <TextField
                        fullWidth
                        type={"number"}
                        onChange={(event: any) => {
                            changeAmountHandler(index, event.target.value);
                        }}
                        value={receipts[index].amount}
                        label="Kwota"
                        variant="outlined"
                        style={{width: '48%'}}
                        disabled={disabled}
                        helperText={validated && receipts[index].amountError}
                        error={!!receipts[index].amountError && validated}
                        required
                    />
                </Grid>
            </>)}
        <Grid item width={ELEMENTS_WIDTH} display={'flex'} justifyContent={'space-between'}>
            <Grid item>
                <Typography variant={"h6"} display={"inline"}>Łączna wartość </Typography>
                <Typography variant={"body1"}
                            display={"inline"}>
                    {sumReceipts(receipts)} zł {sumReceipts(receipts) >= settings.chanceStep &&
                    <span style={{color: "lawngreen"}}>&#10004;</span>}</Typography>
            </Grid>
        </Grid>
        <Grid item width={ELEMENTS_WIDTH} sx={{borderBottom: '1px solid rgba(118, 118, 118, 0.6)'}}/>
    </>
}

export default ReceiptsFields;