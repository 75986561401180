import {Grid, TextField} from "@mui/material";
import {ELEMENTS_WIDTH} from "../style/ui-constants";
import React, {useContext, useEffect} from "react";
import SettingsContext from "../context/settings-context";
import FormContext from "../context/form-context";
import {EMAIL_ID_FIELD} from "../model/Settings";
import validator from "validator";

export const EMAIL_ERROR_MSG = "Niepoprawny email"

const EmailField = () => {
    const {event: {settings}} = useContext(SettingsContext);
    const {email, setEmail, participantExists, validated, formExpanded} = useContext(FormContext);

    useEffect(() => {
        setEmail({value: email.value, error: !email.value ? EMAIL_ERROR_MSG : undefined});
    }, [])

    const emailChangeHandler = (event: { target: { value: any } }) => {
        const value = event.target.value.trim();
        if (!validator.isEmail(value)) {
            setEmail({value, error: EMAIL_ERROR_MSG});
        } else {
            setEmail({value, error: undefined});
        }
    }

    const disabled = participantExists || (settings.identifier === EMAIL_ID_FIELD && formExpanded)

    return <Grid item width={ELEMENTS_WIDTH}>
        <TextField
            fullWidth
            onChange={emailChangeHandler}
            value={email.value}
            label="Email"
            variant="outlined"
            helperText={validated && email.error}
            error={!!email.error && validated}
            disabled={disabled}
            required
        />
    </Grid>
}

export default EmailField;