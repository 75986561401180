import axios from "./api-client";
import {Participant, Receipt} from "../model/Participant"

export const getParticipantByIdentifier = (token: string | undefined, eventSlug: string, identifier: string, identifierValue: string, handleData: (participant: Participant) => void, onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/api/events/' + eventSlug + '/participants?' + identifier + "=" + identifierValue : "";
    const config = {
        headers: {
            "Accept-Content": "application/json",
            'Authorization': 'Bearer ' + token
        }
    }
    axios.get(url, config).then((response) => {
        if (response.status == 200) {
            const participant = Participant.fromApiResponse(response.data);
            handleData(participant);
        } else if (response.status != 200) {
            handleData({} as Participant);
        } else {
            onError && onError(JSON.stringify(response.data));
        }
    }).catch((error) => {
        if (error.response.status == 404) {
            handleData({} as Participant);
        } else {
            console.error(error.response.data ? error.response.data.message : error);
            onError && onError(error.response.data.message ? error.response.data.message : "Błąd serwera!");
        }
    })
}

export const registerParticipant = (token: string | undefined, participant: any, eventSlug: string, handleData: (participant: Participant) => void, onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + `/api/events/${eventSlug}/participants` : '';
    const config = {
        headers: {
            "Accept-Content": "application/json",
            'Authorization': 'Bearer ' + token
        }
    }
    axios.post(url, participant, config).then((response) => {
        if (response.status == 200) {
            const participant = Participant.fromApiResponse(response.data);
            handleData(participant);
        } else if (response.status != 200) {
            handleData({} as Participant);
        } else {
            onError && onError(JSON.stringify(response.data));
        }
    }).catch((error) => {
        console.error(error.response.data ? error.response.data.message : error);
        onError && onError(error.response.data.message ? error.response.data.message : "Błąd serwera!");
    })
}

export const registerNewReceipts = (token: string | undefined, data: any, participantId: string, eventSlug: string, handleData: (receipts: Receipt[], additionalAgreements: Participant) => void, onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + `/api/events/${eventSlug}/participants/${participantId}/receipts` : '';
    const config = {
        headers: {
            "Accept-Content": "application/json",
            'Authorization': 'Bearer ' + token
        }
    }
    axios.post(url, data, config).then((response) => {
        if (response.status == 200) {
            handleData(response.data as Receipt[], response.data as Participant);
        } else if (response.status != 200) {
            handleData([] as Receipt[], {} as Participant);
        } else {
            onError && onError(JSON.stringify(response.data));
        }
    }).catch((error) => {
        console.error(error.response.data ? error.response.data.message : error);
        onError && onError(error.response.data.message ? error.response.data.message : "Błąd serwera!");
    })
}
