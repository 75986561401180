import React, {FC, useContext, useState} from "react";
import {Button, Grid,} from "@mui/material";
import './form.css';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import DoNotDisturb from '@mui/icons-material/DoNotDisturb';
import {EmojiEventsOutlined} from "@mui/icons-material";
import AuthContext from "../context/auth-context";
import AlertContext from "../context/alert-context";
import Form from "./Form";
import FormContext from "../context/form-context";
import Prizes from "./Prizes";
import {ELEMENTS_WIDTH} from "../style/ui-constants";
import SettingsContext from "../context/settings-context";
import {INSTANT_WIN_EVENT_TYPE} from "../model/Event";

const FormContainer: FC = () => {
    const {logout, user} = useContext(AuthContext);
    const {resetInputs, participantExists, prizes} = useContext(FormContext);
    const {setAlert} = useContext(AlertContext);
    const {event: {settings, panelImageUrl}} = useContext(SettingsContext);

    // Handler Prizes Modal
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        if (!participantExists) {
            setAlert({title: "Wyszukaj najpierw uczestnika", severity: "warning"})
            return;
        }
        if (settings.eventType !== INSTANT_WIN_EVENT_TYPE || prizes.filter(p => p.collectedDate).length < settings.maxPrizeTotal) {
            setOpen(true);
        } else {
            setAlert({title: "Uczestnik odebrał już maksymalną liczbę nagród", severity: "warning"})
        }
    }
    const handleClose = () => setOpen(false);

    return (
        <form>
            <div>
                <Prizes onClose={handleClose} open={open}/>
            </div>
            <Grid container component='main' mx={'auto'} width={600} my={4} direction="column" rowSpacing={3}
                  justifyContent='center' alignItems='center'
                  sx={{
                      borderRadius: '50px',
                      background: '#f8f9fa',
                      boxShadow: '8px 8px 16px #7a7a7a, -8px -8px 16px #ffffff'
                  }}
            >
                <Grid item width={ELEMENTS_WIDTH} display={'flex'} justifyContent={'space-between'}>
                    <Button disableRipple color={"inherit"} startIcon={<LockOpenIcon/>} onClick={logout}>
                        Wyloguj
                    </Button>
                    <Button disableRipple color={"inherit"} startIcon={<DoNotDisturb/>}
                            onClick={() => resetInputs()}>
                        Wyczyść
                    </Button>
                    <Button disableRipple color={"inherit"} startIcon={<EmojiEventsOutlined/>}
                            onClick={handleClickOpen}>
                        Nagrody
                    </Button>
                </Grid>
                <Grid item width={ELEMENTS_WIDTH}>
                    <img src={panelImageUrl} alt='Baner' className="image"/>
                </Grid>
                <Form/>
            </Grid>
        </form>
    )
}

export default FormContainer;
