import React, {FC, useContext, useState} from "react";
import SettingsContext from "../context/settings-context";
import AlertContext from "../context/alert-context";
import {EMAIL_ID_FIELD, LOYALTY_CARD_NUMBER_ID_FIELD, PHONE_ID_FIELD} from "../model/Settings";
import {Backdrop, Box, CircularProgress, TextField, Typography} from "@mui/material";
// @ts-ignore
import SignatureCanvas from 'react-signature-canvas';

type PrizeReportProps = {
    firstName?: string;
    lastName?: string;
    identifier: string;
    reward: any;
    collectPrize: (canvas: any, fullName?: string) => void
}

const PrizeReport: FC<PrizeReportProps> = ({firstName, lastName, identifier, reward, collectPrize}) => {
    const {event, event: {settings}} = useContext(SettingsContext);
    const [isLoading, setIsLoading] = useState(false);
    const [fullName, setFullName] = useState('');
    const alertCtx = useContext(AlertContext);
    let sigPad = {} as any;

    const handleCollectPrize = () => {
        if (!sigPad.isEmpty() && ((firstName && lastName) || !!fullName)) {
            setIsLoading(true);
            collectPrize(sigPad.toDataURL('image/png'), fullName)
        } else {
            alertCtx.setAlert({title: 'Uzupełnij raport!', severity: 'warning'})
        }
    }

    const getIdentifierLabel = (identifier: string) => {
        switch (identifier) {
            case PHONE_ID_FIELD:
                return 'Numer telefonu:';
            case EMAIL_ID_FIELD:
                return 'Email:';
            case LOYALTY_CARD_NUMBER_ID_FIELD:
                return 'Numer karty lojalnościowej:';
        }
    }

    return <Box>
        <Backdrop open={isLoading} sx={{
            width: "100%",
            height: "100%",
            zIndex: 1000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}><CircularProgress color={"secondary"}/></Backdrop>
        <Box sx={{width: "20%", mt: 2, ml: "auto", mr: 2, display: "flex", justifyContent: "end"}}>
            <input type='button' className={'button-table'} onClick={(event) => handleCollectPrize()} value={"Zapisz"}/>
        </Box>
        <Box mx={"auto"} p={5} id={"prize-report"}
             sx={{
                 maxWidth: '210mm',
                 minWidth: '210mm',
             }}
        >
            <Typography variant={"h4"} mt={5} mb={5} textAlign={"center"}>Protokół odbioru nagrody</Typography>
            <Typography>Sporządzony w Poznaniu, dnia {(new Date()).toLocaleDateString()}, pomiędzy Organizatorem:
                Expansja Advertising sp. z o.o.,
                z siedzibą w Poznaniu, ul.Kordeckiego 47 (60-144), NIP: 7811808030, zwanym
                dalej <strong>Wydającym</strong>, a</Typography>
            <Typography mt={5}><strong>Imię i nazwisko
                Laureata:</strong> {firstName && lastName ? firstName + ' ' + lastName : ''}
            </Typography>
            {!(firstName && lastName) &&
                <TextField value={fullName} onChange={(event) => setFullName(event.target.value)}/>}
            <Typography><strong>{getIdentifierLabel(settings.identifier)}:</strong> {identifier}</Typography>
            <Typography mt={5} mb={5}>
                Zwanym dalej <strong>Laureatem </strong>
                w celu przekazania Nagrody wygranej w akcji pt. „{event.name}” w CH {event.gallery}, mieszczącym się
                przy ul.
                {event.street} {event.number}, {event.zipCode} {event.city}.
            </Typography>
            <Typography mt={5} mb={5}><strong>Nazwa nagrody:</strong> {reward}</Typography>
            <Box sx={{display: "flex", flexDirection: "row", justifyContent: "end"}}>
                <Box>
                    <Box>
                        <SignatureCanvas penColor='blue' ref={(ref: any) => {
                            sigPad = ref
                        }}
                                         canvasProps={{width: 500, height: 150, className: 'signature'}}/>
                    </Box>
                    <Typography variant={"subtitle2"} textAlign={"center"}>Czytelny podpis uczestnika</Typography>
                </Box>
            </Box>
        </Box>
    </Box>
}

export default PrizeReport;