import React from 'react';
import App from './App';
import {AlertContextProvider} from "./context/alert-context";
import {AuthContextProvider} from "./context/auth-context";
import {BrowserRouter} from "react-router-dom";
import {SettingsContextProvider} from "./context/settings-context";
import ReactDOM from 'react-dom';

const root = document.getElementById('root') as HTMLElement

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <AlertContextProvider>
                <AuthContextProvider>
                    <SettingsContextProvider>
                        <App/>
                    </SettingsContextProvider>
                </AuthContextProvider>
            </AlertContextProvider>
        </BrowserRouter>
    </React.StrictMode>, root
);
