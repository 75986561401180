import axios from "./api-client";
import {Prize} from "../model/Prize";

export const winAndCollect = (token: string | undefined, data: any, eventSlug: string, handleData?: () => void, onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + `/api/events/${eventSlug}/prizes/collect` : '';
    const config = {
        headers: {
            "Accept-Content": "application/json",
            'Authorization' : 'Bearer ' + token
        }
    }
    axios.post(url, data, config).then((response) =>{
        if (response.status == 200) {
            handleData && handleData();
        } else {
            onError && onError(JSON.stringify(response.data));
        }
    }).catch((error) => {
        console.log(error)
        console.error(error.response.data.message ? error.response.data.message : error);
        onError && onError(error.response.data.message ? error.response.data.message : "Błąd serwera!");
    })
}

export const collectPrize = (token: string | undefined, prizeId: string, signature: any, eventSlug: string, handleData?: () => void, onError?: (error: string) => void, fullName?: string) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + `/api/events/${eventSlug}/prizes/${prizeId}/collect` : '';
    const config = {
        headers: {
            "Accept-Content": "application/json",
            'Authorization' : 'Bearer ' + token
        }
    }
    axios.post(url, {participantFullName: fullName, file: signature}, config).then((response) =>{
        if (response.status == 200) {
            handleData && handleData();
        } else {
            onError && onError(JSON.stringify(response.data));
        }
    }).catch((error) => {
        console.log(error)
        console.error(error.response.data.message ? error.response.data.message : error);
        onError && onError(error.response.data.message ? error.response.data.message : "Błąd serwera!");
    })
}

export const getStock = (token: string | undefined, eventSlug: string, handleData?: (prizes: Prize[]) => void, onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + `/api/events/${eventSlug}/prizes/stock` : '';
    const config = {
        headers: {
            "Accept-Content": "application/json",
            'Authorization' : 'Bearer ' + token
        }
    }
    axios.get(url, config).then((response) =>{
        if (response.status == 200) {
            const prizes = response.data
            handleData && handleData(prizes);
        } else {
            onError && onError(JSON.stringify(response.data.message));
        }
    }).catch((error) => {
        console.error(error.response && error.response.data.message ? error.response.data.message : error);
        onError && onError(error.response.data.message ? error.response.data.message : "Błąd serwera!");
    })
}

export const verifyCode = (token: string | undefined, code: string, eventSlug: string, handleData?: () => void, onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + `/api/events/${eventSlug}/prizes/verify` : '';
    const config = {
        headers: {
            "Accept-Content": "application/json",
            'Authorization' : 'Bearer ' + token
        }
    }
    axios.post(url, {code}, config).then((response) =>{
        if (response.status == 200) {
            handleData && handleData();
        } else {
            onError && onError(JSON.stringify(response.data));
        }
    }).catch((error) => {
        console.log(error)
        console.error(error.response.data.message ? error.response.data.message : error);
        onError && onError(error.response.data.message ? error.response.data.message : "Błąd serwera!");
    })
}
