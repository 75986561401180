export class Shop {
    id: string;
    name: string;

    private constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }

    public static fromApiResponse(data: any) {
        return new Shop(
            data?.id ? data.id : '',
            data?.name ? data?.name : ''
        )
    }


}
