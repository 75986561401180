import CentralLayout from "../layout/CentralLayout";
import bgImage from "../assets/bg-sign-in-basic.jpeg";
import FormContainer from "../components/FormContainer";
import {useContext, useEffect} from "react";
import {getEventSettings} from "../api/content-api";
import AuthContext from "../context/auth-context";
import AlertContext from "../context/alert-context";
import SettingsContext from "../context/settings-context";
import {LinearProgress} from "@mui/material";
import {FormContextProvider} from "../context/form-context";

const FormPage = () => {
    const {user} = useContext(AuthContext);
    const {event, setEvent} = useContext(SettingsContext);
    const {setAlert} = useContext(AlertContext);

    useEffect(() => {
        getEventSettings(user?.token, (event) => {
            document.title = event.name
            setEvent(event);
        }, (error) => {
            setAlert({title: error})
        })
    }, [])

    return (
        <CentralLayout image={event.name === '' ? bgImage : null}>
            {event.name !== '' &&
                <FormContextProvider>
                    <FormContainer/>
                </FormContextProvider>
            }
            {event.name === '' && <LinearProgress color={"warning"}/>}
        </CentralLayout>
    );
}

export default FormPage;
