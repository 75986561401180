import {Box, Button, Grid, IconButton, Modal, TextField, Typography} from "@mui/material";
import React, {useContext, useState} from "react";
import SettingsContext from "../context/settings-context";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FormContext, {Field} from "../context/form-context";
import {SCANNED_COUPONS_EVENT_TYPE} from "../model/Event";
import barcode from "../assets/images/barcode.png";
import {COUPON_ERROR_MSG, ScannerButton} from "./CouponsFields";
import {ELEMENTS_WIDTH} from "../style/ui-constants";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

const ModalBox = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: '405px',
    transform: 'translate(-50%, -50%)',
    border: '1px solid #000',
    boxShadow: 24,
    backgroundColor: '#fff',
}

const ExtraSMSCoupons = () => {
    const {extraChances, newExtraChances, setNewExtraChances} = useContext(FormContext);
    const {event: {settings}} = useContext(SettingsContext);
    const {extraCoupons, setExtraCoupon, setExtraCoupons, validated, coupons} = useContext(FormContext);
    const [index, setIndex] = useState<number>(-1);

    const disabled = newExtraChances === settings.maxExtraChancesPerRegistration ||
        (extraChances + newExtraChances) === settings.maxExtraChancesTotal

    const handleAddChance = () => {
        setNewExtraChances(newExtraChances + 1)
        if (settings.eventType === SCANNED_COUPONS_EVENT_TYPE) {
            setExtraCoupons([...extraCoupons, {value: '', error: COUPON_ERROR_MSG}])
        }
    }

    const handleRemoveChance = () => {
        if (newExtraChances > 0) {
            setNewExtraChances(newExtraChances - 1)
            if (settings.eventType === SCANNED_COUPONS_EVENT_TYPE) {
                setExtraCoupons([...extraCoupons.slice(0, -1)])
            }
        }
    }

    const changeHandler = (index: number, value: string) => {
        const trimmed = value.trim().replace(/[^0-9]/g, '');
        if (trimmed.length != 8 || checkOtherCoupons(trimmed, coupons, false) || checkOtherCoupons(trimmed, extraCoupons)) {
            setExtraCoupon(index, {value: trimmed, error: COUPON_ERROR_MSG});
        } else {
            setExtraCoupon(index, {value: trimmed, error: undefined});
        }
    }

    const checkOtherCoupons = (value: string, coupons: Field[], sameCollection: boolean = true) => {
        const codes = coupons.map(c => c.value)
        return codes.filter((item, index) => (sameCollection ? codes.indexOf(value) !== index : true) && codes.indexOf(value) != -1).length > 0;
    }


    return <>
        {settings.extraChances &&
            <Grid item container width={ELEMENTS_WIDTH}
                  mb={4}
            >
                <Typography
                    variant={"h6"}>Dodatkowe {settings.eventType === SCANNED_COUPONS_EVENT_TYPE ? 'kupony' : 'kody'}</Typography>
                <Grid
                    item
                    container
                    my={1}
                    sx={{
                        color: "grey"
                    }}
                >
                    Przyznane łącznie: {extraChances + newExtraChances}/{settings.maxExtraChancesTotal}
                </Grid>
                <Grid
                    item
                    container
                    mt={2}
                >
                    <Grid
                        item
                        container
                        xs={6}
                    >
                        <Button
                            variant={"contained"}
                            fullWidth
                            endIcon={<AddIcon/>}
                            disabled={disabled}
                            onClick={handleAddChance}
                        >
                            Dodaj
                        </Button>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={3}
                        alignItems={'center'}
                        justifyContent={"center"}
                    >
                        {newExtraChances}
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent={"end"}
                        xs={3}
                    >
                        <IconButton
                            color="error"
                            onClick={handleRemoveChance}
                        >
                            <RemoveIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    sx={{
                        color: 'grey',
                        fontSize: '12px',
                        fontStyle: 'italic'
                    }}
                >
                    {newExtraChances === settings.maxExtraChancesPerRegistration && 'Wykorzystano limit dla jednej rejestracji'}
                </Grid>
                {settings.eventType === SCANNED_COUPONS_EVENT_TYPE && Array(newExtraChances).fill(null).map((e: any, i: number) => (
                    <Grid container item justifyContent="space-between" width={ELEMENTS_WIDTH} mt={2}>
                        <TextField
                            sx={{width: '420px'}}
                            onChange={(event) => changeHandler(i, event.target.value)}
                            value={extraCoupons[i].value}
                            label={"Kupon " + (i + 1)}
                            variant="outlined"
                            helperText={validated && extraCoupons[i].error}
                            error={!!extraCoupons[i].error && validated}
                            required
                        />
                        <ScannerButton onClick={() => setIndex(i)}>
                            <Box component="img" sx={{width: '44px'}} src={barcode}/>
                        </ScannerButton>
                    </Grid>))}
                <Modal
                    open={index >= 0}
                    onClose={() => setIndex(-1)}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box sx={ModalBox}>
                        <BarcodeScannerComponent
                            width={400}
                            height={400}
                            facingMode={"environment"}
                            onUpdate={(err, result) => {
                                if (result) {
                                    changeHandler(index, result['text'])
                                    setIndex(-1);
                                } else return coupons[index].value
                            }}
                            stopStream={!!index}
                        />
                    </Box>
                </Modal>
            </Grid>
        }
    </>;
}
export default ExtraSMSCoupons;