import {Grid, TextField} from "@mui/material";
import {ELEMENTS_WIDTH} from "../style/ui-constants";
import React, {useContext, useEffect} from "react";
import SettingsContext from "../context/settings-context";
import FormContext from "../context/form-context";
import {PHONE_ID_FIELD} from "../model/Settings";

export const PHONE_ERROR_MSG = "Niepoprawny numer telefonu"

const PhoneField = () => {
    const {event: {settings}} = useContext(SettingsContext);
    const {phone, setPhone, participantExists, validated} = useContext(FormContext);

    useEffect(() => {
        setPhone({value: phone.value, error: !phone.value ? PHONE_ERROR_MSG : undefined});
    }, [])

    const phoneNumberChangeHandler = (event: { target: { value: any } }) => {
        const value = event.target.value.trim().replace(/[^0-9]/g, '');
        if (value.length < 9) {
            setPhone({value, error: PHONE_ERROR_MSG});
        } else {
            setPhone({value, error: undefined});
        }
    }

    return <>
        {(settings.phoneRequired || settings.identifier === PHONE_ID_FIELD) ? <Grid item width={ELEMENTS_WIDTH}>
            <TextField
                fullWidth
                onChange={phoneNumberChangeHandler}
                value={phone.value}
                label="Numer telefonu"
                variant="outlined"
                helperText={validated && phone.error}
                error={!!phone.error && validated}
                disabled={participantExists}
                required
            />
        </Grid> : <></>}
    </>
}

export default PhoneField;