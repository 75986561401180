import {Grid, TextField} from "@mui/material";
import {ELEMENTS_WIDTH} from "../style/ui-constants";
import React, {useContext, useEffect} from "react";
import SettingsContext from "../context/settings-context";
import FormContext from "../context/form-context";

const FIRST_NAME_ERROR_MSG = "Niepoprawna wartość dla pola 'imię'";

const FirstNameField = () => {
    const {event: {settings}} = useContext(SettingsContext);
    const {firstName, setFirstName, participantExists, validated} = useContext(FormContext);

    useEffect(() => {
        setFirstName({value: firstName.value, error: !firstName.value ? FIRST_NAME_ERROR_MSG : undefined});
    }, [])

    const changeHandler = (event: { target: { value: any } }) => {
        const value = event.target.value.trim();
        if (value.length <= 1) {
            setFirstName({value, error: FIRST_NAME_ERROR_MSG});
        } else {
            setFirstName({value, error: undefined});
        }
    }

    return <>
        {settings.firstNameRequired ? <Grid item width={ELEMENTS_WIDTH}>
            <TextField
                fullWidth
                onChange={changeHandler}
                value={firstName.value}
                label="Imię"
                variant="outlined"
                helperText={validated && firstName.error}
                error={!!firstName.error && validated}
                disabled={participantExists}
                required
            />
        </Grid> : <></>}
    </>
}

export default FirstNameField;