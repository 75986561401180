import {Box, Button, Grid, Modal, styled, TextField} from "@mui/material";
import {ELEMENTS_WIDTH} from "../style/ui-constants";
import React, {useContext, useEffect, useState} from "react";
import SettingsContext from "../context/settings-context";
import FormContext from "../context/form-context";
import {LOYALTY_CARD_NUMBER_ID_FIELD} from "../model/Settings";
import barcode from "../assets/images/barcode.png";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

export const LOYALTY_CARD_NUMBER_ERROR_MSG = "Niepoprawny numer karty"

const ScannerButton = styled(Button)({
    width: '60px',
    borderRadius: '0.375rem',
    border: '1px solid rgba(118, 118, 118, 0.4) !important',
    padding: '0',
    marginLeft: '10px'
})

const ModalBox = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: '405px',
    transform: 'translate(-50%, -50%)',
    border: '1px solid #000',
    boxShadow: 24,
    backgroundColor: '#fff',
}

const LoyaltyCardNumberField = () => {
    const {event: {settings}} = useContext(SettingsContext);
    const {loyaltyCardNumber, setLoyaltyCardNumber, participantExists, validated} = useContext(FormContext);
    const [openScanner, setOpenScanner] = useState(false);

    useEffect(() => {
        setLoyaltyCardNumber({
            value: loyaltyCardNumber.value,
            error: !loyaltyCardNumber.value ? LOYALTY_CARD_NUMBER_ERROR_MSG : undefined
        });
    }, [])

    const loyaltyCardNumberChangeHandler = (value: any) => {
        const trimmed = value.trim().replace(/[^0-9]/g, '');
        if (trimmed.length <= 6) {
            setLoyaltyCardNumber({value: trimmed, error: LOYALTY_CARD_NUMBER_ERROR_MSG});
        } else {
            setLoyaltyCardNumber({value: trimmed, error: undefined});
        }
    }

    return <>
        {(settings.loyaltyCardNumberRequired || settings.identifier === LOYALTY_CARD_NUMBER_ID_FIELD) ?
            <Grid item container width={ELEMENTS_WIDTH} wrap={'nowrap'}>
                <TextField
                    fullWidth
                    onChange={(event) => loyaltyCardNumberChangeHandler(event.target.value)}
                    value={loyaltyCardNumber.value}
                    label="Numer karty lojalnościowej"
                    variant="outlined"
                    helperText={validated && loyaltyCardNumber.error}
                    error={!!loyaltyCardNumber.error && validated}
                    disabled={participantExists}
                    required
                />
                <ScannerButton onClick={() => setOpenScanner(true)}>
                    <Box component="img" sx={{width: '44px'}} src={barcode}/>
                </ScannerButton>
                <Modal
                    open={openScanner}
                    onClose={() => setOpenScanner(false)}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box sx={ModalBox}>
                        <BarcodeScannerComponent
                            width={400}
                            height={400}
                            facingMode={"environment"}
                            onUpdate={(err, result) => {
                                console.log(err)
                                console.log(result)
                                if (result) {
                                    loyaltyCardNumberChangeHandler(result['text'])
                                    setOpenScanner(false);
                                } else return loyaltyCardNumber.value
                            }}
                            stopStream={openScanner}
                        />
                    </Box>
                </Modal>
            </Grid> : <></>}
    </>
}

export default LoyaltyCardNumberField;