import Grid from "@mui/material/Grid";
import {Box} from "@mui/material";
import {useContext} from "react";
import AlertContext from "../context/alert-context";
import CustomSnackbar from "../components/CustomSnackbar";

type Props = {
    image?: string | null,
    children: any
}

function CentralLayout({image, children}: Props) {
    const alertCtx = useContext(AlertContext);

    return (
        <Grid
            item
            container
            sx={{
                maxWidth: "100vw",
                height: "100vh",
                position: "relative"
            }}
        >
            <Grid item container justifyContent="center" alignItems="center" height="100%">
                <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
                    {children}
                </Grid>
            </Grid>
            <Box
                sx={{
                    position: "absolute",
                    width: "100%",
                    minHeight: "100vh",
                    zIndex: -1,
                    backgroundImage:
                        image &&
                        `linear-gradient(
                            rgb(66, 66, 74, 0.6),
                            rgb(25, 25, 25, 0.6)
                        ), url(${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}/>
            <CustomSnackbar open={alertCtx.open} title={alertCtx.title} xl={alertCtx.xl} severity={alertCtx.severity}/>
        </Grid>
    );
}


export default CentralLayout;
