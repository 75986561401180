import {Box, Dialog, Grid, TextField, Typography} from "@mui/material";
import React, {FC, useContext, useEffect, useState} from "react";
import './prizes.css';
import {collectPrize, getStock, verifyCode, winAndCollect} from "../api/prize-api";
import AlertContext from "../context/alert-context";
// @ts-ignore
import SignatureCanvas from 'react-signature-canvas';
import SettingsContext from "../context/settings-context";
import {PrizeType} from "../model/Prize";
import {INSTANT_WIN_EVENT_TYPE} from "../model/Event";
import Settings, {EMAIL_ID_FIELD, LOYALTY_CARD_NUMBER_ID_FIELD, PHONE_ID_FIELD} from "../model/Settings";
import FormContext, {FormContextType} from "../context/form-context";
import PrizeReport from "./PrizesReport";
import AuthContext from "../context/auth-context";

type DialogProps = {
    open: boolean;
    onClose: () => any;
}

type TableProps = {
    rewards: Array<any>;
    prizeCollected?: boolean;
    selectPrize: (item: any, code?: string) => void
}

const StockTable: FC<TableProps> = ({rewards, selectPrize, prizeCollected}) => {
    const {event: {settings, slug}} = useContext(SettingsContext);
    const smsValidation = settings.identifier === 'phone';
    const [code, setCode] = useState('');
    const [valid, setValid] = useState(!smsValidation);
    const alertCtx = useContext(AlertContext);
    const {user} = useContext(AuthContext);

    const verify = () => {
        const rex = new RegExp('^[0-9]{8}$')
        const normalizedCode = code.trim();

        if (rex.test(normalizedCode)) {
            verifyCode(user?.token, normalizedCode, slug, () => {
                setValid(true)
                alertCtx.setAlert({title: 'Poprawny kod sms', severity: 'success'})
            }, (error) => {
                console.log(error);
                alertCtx.setAlert({title: error})
            })
        } else {
            alertCtx.setAlert({title: 'Kod sms musi składać się z 8 cyfr'})
        }
    }


    return <div>
        {smsValidation &&
            <Grid
                item
                container
                sx={{
                    padding: '15px 20px'
                }}
                alignItems={'center'}
            >
                <Typography
                    variant={'h6'}
                    sx={{
                        marginRight: '20px'
                    }}
                >
                    KOD SMS:
                </Typography>
                <TextField
                    value={code}
                    disabled={valid}
                    onChange={({target: {value}}) => setCode(value)}
                />
                <Box
                    component={'input'}
                    type={'button'}
                    value={'Sprawdź'}
                    onClick={() => verify()}
                    sx={{
                        marginLeft: '20px',
                        width: '200px',
                        textAlign: 'center',
                        backgroundColor: '#014282',
                        color: '#fff',
                        padding: '6px',
                        borderColor: 'transparent',
                        boxShadow: '0 7px 10px 0 rgba(115, 115, 115, 1)',
                        borderRadius: '50px',
                        fontSize: '20px',
                        transition: 'all .3s',

                        '&:hover': {
                            cursor: 'pointer',
                        },

                        '&:focus': {
                            '-webkit-box-shadow': '0 0 0 0 rgba(115, 115, 115, 1)',
                            '-moz-box-shadow': '0 0 0 0 rgba(115, 115, 115, 1)',
                            boxShadow: '0 0 0 0 rgba(115, 115, 115, 1)',
                        }
                    }}
                />
                {valid &&
                    <Typography
                        variant={'h6'}
                        sx={{
                            marginLeft: '20px',
                            color: "lawngreen"
                        }}
                    >
                        &#10004;
                    </Typography>
                }
            </Grid>
        }

        <table className='table'>
            <thead>
            <tr>
                <th>Stan nagród</th>
                <th>Nagroda</th>
                <th>Potwierdzenie</th>
            </tr>
            </thead>
            <tbody>
            {rewards.length > 0 ? rewards.map((item, index) => (
                    <tr key={index}>
                        <td>{item.number}</td>
                        <td>{item.name}</td>
                        <td>
                            <input type='button'
                                   className={`button-table ${item.number <= 0 || prizeCollected || !valid ? ' none' : ''}`}
                                   data-id={item.name} disabled={item.number <= 0 || prizeCollected || !valid}
                                   value={item.number > 0 ? 'Odbierz nagrodę' : 'Brak nagrody'}
                                   onClick={(event) => selectPrize(item, code)}/>
                        </td>
                    </tr>
                )) :
                <tr>
                    <td colSpan={5}>
                        <Typography sx={{width: "100%"}} variant={"caption"}>Brak nagród</Typography>
                    </td>
                </tr>
            }
            </tbody>
        </table>
    </div>
}

const WonTable: FC<TableProps> = ({rewards, selectPrize}) => {
    return <table className='table'>
        <thead>
        <tr>
            <th>Numer referencyjny</th>
            <th>Nagroda</th>
            <th>Data wygranej</th>
            <th>Data odbioru</th>
            <th>Potwierdzenie</th>
        </tr>
        </thead>
        <tbody>
        {rewards.length > 0 ? rewards.map((item, index) => (
                <tr key={index}>
                    <td>{item.refNum}</td>
                    <td>{item.name}</td>
                    <td>{item.wonDate ? item.wonDate.toLocaleDateString() : ''}</td>
                    <td>{item.collectedDate ? item.collectedDate.toLocaleDateString() : ''}</td>
                    <td>
                        <input type='button'
                               className={`button-table ${!!item.collectedDate ? ' none' : ''}`}
                               data-id={item.name} disabled={!!item.collectedDate}
                               value={!item.collectedDate ? 'Odbierz nagrodę' : 'Odebrano'}
                               onClick={(event) => selectPrize(item)}/>
                    </td>
                </tr>
            )) :
            <tr>
                <td colSpan={5}>
                    <Typography sx={{width: "100%"}} variant={"caption"}>Brak nagród</Typography>
                </td>
            </tr>
        }
        </tbody>
    </table>
}

const Prizes: FC<DialogProps> = ({open, onClose}) => {
    const {event: {settings, slug}} = useContext(SettingsContext);
    const {user} = useContext(AuthContext);
    const formCtx = useContext(FormContext);
    const alertCtx = useContext(AlertContext);
    const [prize, setPrize] = useState<any>(null);
    const [couponCode, setCouponCode] = useState<string | undefined>('');
    const [rewards, setRewards] = useState<PrizeType[]>([]);
    const [collectedPrize, setCollectedPrize] = useState(false)

    const getIdentifier = (ctx: FormContextType, settings: Settings) => {
        switch (settings.identifier) {
            case EMAIL_ID_FIELD:
                return ctx.email.value;
            case PHONE_ID_FIELD:
                return ctx.phone.value;
            case LOYALTY_CARD_NUMBER_ID_FIELD:
                return ctx.loyaltyCardNumber.value
            default:
                return ''
        }
    }
    const handleClose = () => {
        onClose()
    };

    const collectPrizes = () => {
        getStock(user?.token, slug, (prizes) => {
            setRewards(prizes)
        }, (error) => {
            console.log(error);
            alertCtx.setAlert({title: error})
        })
    }

    useEffect(() => {
        setCollectedPrize(false)
        if (settings.eventType === INSTANT_WIN_EVENT_TYPE) {
            collectPrizes()
        } else if (formCtx.participantExists) {
            setRewards(formCtx.prizes)
        }
    }, [open])

    const collectReward = (canvas: any, fullName?: string) => {

        const data = {
            "participant_id": formCtx.id,
            "prize_name": prize.name,
            "file": canvas,
            code: couponCode,
            participant_full_name: fullName
        }
        if (settings.eventType === INSTANT_WIN_EVENT_TYPE) {
            winAndCollect(user?.token, data, slug, () => {
                alertCtx.setAlert({title: 'Potwierdzono odbiór nagrody!', severity: 'success'})
                formCtx.addPrize({name: prize.name});
                setPrize(null);
                setCollectedPrize(true)
                collectPrizes()
                handleClose()
            }, (error) => {
                setPrize(null);
                console.log(error);
                alertCtx.setAlert({title: error})
            })
        } else {
            collectPrize(user?.token, prize.id, canvas, slug, () => {
                alertCtx.setAlert({title: 'Potwierdzono odbiór nagrody!', severity: 'success'})
                prize.collectedDate = new Date()
                setRewards(() => ([...rewards]))
                setPrize(null);
            }, (error) => {
                setPrize(null);
                console.log(error);
                alertCtx.setAlert({title: error})
            }, fullName)
        }

    }


    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={'lg'}>
            {prize && <PrizeReport firstName={formCtx.firstName.value} lastName={formCtx.lastName.value}
                                   identifier={getIdentifier(formCtx, settings)} reward={prize.name}
                                   collectPrize={collectReward}/>}
            {settings.eventType === INSTANT_WIN_EVENT_TYPE && !prize &&
                <StockTable rewards={rewards} prizeCollected={collectedPrize}
                            selectPrize={(item, code) => {
                                setPrize(item);
                                setCouponCode(code)
                            }}/>}
            {settings.eventType !== INSTANT_WIN_EVENT_TYPE && !prize &&
                <WonTable rewards={rewards} selectPrize={(item) => setPrize(item)}/>}
        </Dialog>
    )
}

export default Prizes;
