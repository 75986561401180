import React, {createContext, FC, useContext, useState} from "react";
import AlertContext from "./alert-context";
import {useNavigate} from "react-router-dom";
import validator from "validator";
import {login, logout} from "../api/auth-api";
import {User} from "../model/User";

export type AuthContextType = {
    user: User | null;
    isLoggedIn: boolean;
    login: (email: string, password: string) => void;
    logout: () => void;
}

const InitialAuthContext: AuthContextType = {
    user: null,
    isLoggedIn: false,
    login: (email, password) => {
    },
    logout: () => {
    },
}

const AuthContext = createContext(InitialAuthContext);

type Props = {
    children: any,
}
export const AuthContextProvider: FC<Props> = ({children}: Props) => {
    const alertCtx = useContext(AlertContext);
    const navigate = useNavigate();

    const localData = localStorage.getItem("user")
    const initialUser = JSON.parse(localData ? localData : '{}') as User | null;
    const [user, setUser] = useState<User | null>(initialUser);

    const userIsLoggedIn = !!user;

    const validateEmail = (email: string) => {
        if (!validator.isEmail(email)) {
            alertCtx.setAlert({title: "Email musi mieć poprawny format!"});
            return false;
        }
        return true;
    }

    const validatePassword = (password: string) => {
        if (password == "" || password.length < 6) {
            alertCtx.setAlert({title: "Hasło musi mieć poprawny format (min. 6 znaków)"});
            return false;
        }
        return true;
    }

    const loginHandler = (email: string, password: string) => {
        const isEmailValid = validateEmail(email);
        const isPasswordValid = validatePassword(password);
        if (!isEmailValid || !isPasswordValid) {
            return;
        }
        login({email, password}, (user: User) => {
            setUser(user);
            localStorage.setItem('user', JSON.stringify(user));
            navigate("/" + user.access, {replace: true});
        }, (error: string) => {
            alertCtx.setAlert({title: error});
        })
    };

    const logoutHandler = () => {
        logout(() => {
            setUser(null);
            localStorage.removeItem("user");
            navigate("/login", {replace: true});
        }, (error: string) => {
            alertCtx.setAlert({title: error});
        })
    };

    const contextValue: AuthContextType = {
        user: user,
        isLoggedIn: userIsLoggedIn,
        login: loginHandler,
        logout: logoutHandler,
    }

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

export default AuthContext;
