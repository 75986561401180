import React, {useCallback, useContext, useEffect} from "react";
import FormContext from "../context/form-context";
import {debounce, Grid} from "@mui/material";
import {ELEMENTS_WIDTH} from "../style/ui-constants";
import SettingsContext from "../context/settings-context";
import {getParticipantByIdentifier} from "../api/participant-api";
import AlertContext from "../context/alert-context";
import AuthContext from "../context/auth-context";
import {EMAIL_ID_FIELD, LOYALTY_CARD_NUMBER_ID_FIELD, PHONE_ID_FIELD} from "../model/Settings";
import PhoneField from "./PhoneField";
import EmailField from "./EmailField";
import LoyaltyCardNumberField from "./LoyaltyCardNumberField";

const IdentifierField = () => {
    const {event} = useContext(SettingsContext);
    const {user} = useContext(AuthContext);
    const {setAlert} = useContext(AlertContext);
    const {
        phone,
        email,
        loyaltyCardNumber,
        participantExists,
        setParticipantExists,
        formExpanded,
        setFormExpanded,
        resetInputs,
        setParticipant
    } = useContext(FormContext);

    useEffect(() => {
        if (phone && !phone.error && event.settings.identifier === PHONE_ID_FIELD) {
            checkUser(event, phone.value);
        }
    }, [phone])

    useEffect(() => {
        if (email && !email.error && event.settings.identifier === EMAIL_ID_FIELD) {
            checkUser(event, email.value);
        }
    }, [email])

    useEffect(() => {
        if (loyaltyCardNumber && !loyaltyCardNumber.error && event.settings.identifier === LOYALTY_CARD_NUMBER_ID_FIELD) {
            checkUser(event, loyaltyCardNumber.value);
        }
    }, [loyaltyCardNumber])

    const checkUser = useCallback(debounce((event, value) => {
        if (!participantExists && value && value.length > 0) {
            getParticipantByIdentifier(user?.token, event.slug, event.settings.identifier, value, (participant) => {
                if (Object.keys(participant).length === 0) {
                    resetInputs(event.settings.identifier)
                    setFormExpanded(true)
                    setParticipantExists(false)
                } else {
                    setFormExpanded(true)
                    setParticipantExists(true)
                    setParticipant(participant)
                    if (participant?.prizes && participant.prizes.filter(p => p.collectedDate).length >= event.settings.maxPrizeTotal) {
                        setAlert({title: 'Uczestnik odebrał już maksymalną ilość nagród!', severity: 'warning'})
                    }
                }
            }, (error) => {
                setAlert({title: error})
            })
        }
    }, 1000), [participantExists])

    return (
        <Grid container item width={ELEMENTS_WIDTH} pb={formExpanded ? 0 : 4} justifyContent="space-between">
            {event.settings.identifier === PHONE_ID_FIELD && <PhoneField/>}
            {event.settings.identifier === EMAIL_ID_FIELD && <EmailField/>}
            {event.settings.identifier === LOYALTY_CARD_NUMBER_ID_FIELD && <LoyaltyCardNumberField/>}
        </Grid>
    )
}

export default IdentifierField