import {Grid, TextField} from "@mui/material";
import {ELEMENTS_WIDTH} from "../style/ui-constants";
import React, {useContext, useEffect} from "react";
import SettingsContext from "../context/settings-context";
import FormContext from "../context/form-context";

const ZIP_CODE_ERROR_MSG = "Niepoprawny kod pocztowy";

const ZipCodeField = () => {
    const {event: {settings}} = useContext(SettingsContext);
    const {zipCode, setZipCode, participantExists, validated} = useContext(FormContext);

    useEffect(() => {
        setZipCode({value: zipCode.value, error: !zipCode.value ? ZIP_CODE_ERROR_MSG : undefined});
    }, [])

    const changeHandler = (event: { target: { value: any } }) => {
        const reg = RegExp('^[0-9]{2}-[0-9]{3}$')
        const value = event.target.value.trim();
        if (!reg.test(value)) {
            setZipCode({value, error: ZIP_CODE_ERROR_MSG});
        } else {
            setZipCode({value, error: undefined});
        }
    }

    return <>
        {settings.zipCodeRequired ? <Grid item width={ELEMENTS_WIDTH}>
            <TextField
                fullWidth
                onChange={changeHandler}
                value={zipCode.value}
                label="Kod pocztowy"
                variant="outlined"
                helperText={validated && zipCode.error}
                error={!!zipCode.error && validated}
                disabled={participantExists}
                required
            />
        </Grid> : <></>}
    </>
}

export default ZipCodeField;