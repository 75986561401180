import React, {useContext} from "react";
import SettingsContext from "../context/settings-context";
import ExtraSMSCoupons from "./ExtraSMSCoupons";
import {Grid} from "@mui/material";
import {ELEMENTS_WIDTH} from "../style/ui-constants";

const ExtraCoupons = () => {
    const {event: {settings}} = useContext(SettingsContext);

    return (
        <Grid item container width={ELEMENTS_WIDTH}
              mb={4}
        >
            {settings.extraChances && <ExtraSMSCoupons/>}
        </Grid>
    );
}

export default ExtraCoupons;