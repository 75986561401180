import React, {FC, useContext, useEffect} from "react";
import {Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Typography,} from '@mui/material'
import {ELEMENTS_WIDTH} from "../style/ui-constants";
import SettingsContext from "../context/settings-context";
import FormContext from "../context/form-context";

export const AGREEMENTS_ERROR_MSG = "Wszystkie zgody powinny być zaznaczone"
const AgreementSection: FC = () => {
    const {event: {settings, statuteUrl}} = useContext(SettingsContext);
    const {setAgreement, setAgreements, agreements, participantExists, validated} = useContext(FormContext);

    useEffect(() => {
        setAgreements(settings.agreements.map(a => participantExists))
    }, [participantExists])

    const error = validated && !agreements.reduce((a, b) => a && b)

    return (
        <Grid item width={ELEMENTS_WIDTH}>
            <FormControl
                required
                error={error}
                component="fieldset"
                variant="standard"
            >
                <FormLabel component="legend">Zgody</FormLabel>
                <FormGroup>
                    {agreements.length === settings.agreements.length && settings.agreements.map((a: string, i: number) =>
                        <FormControlLabel
                            key={'checkobox-' + i}
                            control={
                                <Checkbox sx={error ? {color: "red"} : {}} checked={agreements[i]}
                                          onChange={(event) => setAgreement(i, event.target.checked)}
                                          name={'agreement-' + i}/>
                            }
                            disabled={participantExists}
                            label={a}
                            labelPlacement="end"
                            sx={{display: 'flex', flexDirection: "row", marginTop: 1}}
                        />)}
                    <a style={{paddingLeft: "30px", fontSize: "14px", marginTop: "10px", marginBottom: "30px"}}
                       href={statuteUrl}
                       target={"_blank"}>Regulamin</a>
                </FormGroup>
                {error &&
                    <Typography color="error" variant="body1" mt={2}>{AGREEMENTS_ERROR_MSG}</Typography>
                }
            </FormControl>
        </Grid>
    )
}

export default AgreementSection;
