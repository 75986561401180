import React, {createContext, FC, useState} from "react";
import {Participant} from "../model/Participant";
import {EMAIL_ID_FIELD, LOYALTY_CARD_NUMBER_ID_FIELD, PHONE_ID_FIELD} from "../model/Settings";
import {PrizeType} from "../model/Prize";

export type Field = {
    value: string;
    error: string | undefined;
}

const InitialFieldValue = {
    value: '',
    error: undefined
}

export type FormContextType = {
    validated: boolean;
    participantExists: boolean;
    formExpanded: boolean;
    id: string;
    email: Field;
    phone: Field;
    firstName: Field;
    lastName: Field;
    loyaltyCardNumber: Field;
    zipCode: Field;
    receipts: ReceiptType[];
    agreements: boolean[];
    prizes: PrizeType [];
    coupons: Field[];
    extraCoupons: Field[];
    extraChances: number;
    newExtraChances: number;
    setEmail: (value: Field) => void;
    setPhone: (value: Field) => void;
    setFirstName: (value: Field) => void;
    setLastName: (value: Field) => void;
    setLoyaltyCardNumber: (value: Field) => void;
    setZipCode: (value: Field) => void;
    setParticipantExists: (value: boolean) => void;
    setFormExpanded: (value: boolean) => void;
    resetInputs: (except?: string) => void;
    setParticipant: (value: Participant) => void;
    setReceipt: (index: number, receipt: ReceiptType) => void;
    setCoupon: (index: number, value: Field) => void;
    setCoupons: (coupons: Field[]) => void;
    setReceipts: (receipts: ReceiptType[]) => void;
    setAgreement: (index: number, value: boolean) => void;
    setReceiptsNumber: (num: number) => void;
    setAgreements: (values: boolean[]) => void;
    setValidated: (value: boolean) => void;
    addPrize: (prize: PrizeType) => void;
    setNewExtraChances: (value: number) => void;
    setExtraCoupon: (index: number, value: Field) => void;
    setExtraCoupons: (coupons: Field[]) => void;

}

export type ReceiptType = {
    amount?: string | null;
    amountError?: string | null;
    shopName?: string | null;
    shopNameError?: string | null;
    date?: Date | null;
    dateError?: string | null;
    number?: string | null;
    numberError?: string | null;
}

const EmptyReceipt = {
    shopName: null,
    shopNameError: null,
    amount: null,
    amountError: null,
    date: null,
    dateError: null,
    number: null,
    numberError: null
}

const InitialFormContext: FormContextType = {
    validated: false,
    participantExists: false,
    formExpanded: false,
    id: '',
    email: InitialFieldValue,
    phone: InitialFieldValue,
    firstName: InitialFieldValue,
    lastName: InitialFieldValue,
    loyaltyCardNumber: InitialFieldValue,
    zipCode: InitialFieldValue,
    receipts: [EmptyReceipt],
    agreements: [false],
    prizes: [],
    coupons: [],
    extraCoupons: [],
    extraChances: 0,
    newExtraChances: 0,
    setEmail: (value: Field) => {
    },
    setPhone: (value: Field) => {
    },
    setFirstName: (value: Field) => {
    },
    setLastName: (value: Field) => {
    },
    setLoyaltyCardNumber: (value: Field) => {
    },
    setZipCode: (value: Field) => {
    },
    setParticipantExists: (value: boolean) => {
    },
    setFormExpanded: (value: boolean) => {
    },
    resetInputs: (except?) => {
    },
    setParticipant: (value: Participant) => {
    },
    setReceipt: (index: number, receipt: ReceiptType) => {
    },
    setCoupon: (index: number, value: Field) => {
    },
    setCoupons: (coupons: Field[]) => {
    },
    setReceipts: (receipts: ReceiptType[]) => {
    },
    setAgreement: (index: number, value: boolean) => {
    },
    setReceiptsNumber: () => {
    },
    setAgreements: (values: boolean[]) => {
    },
    setValidated: (value: boolean) => {
    },
    addPrize: (prize: PrizeType) => {
    },
    setNewExtraChances:(value: number) => {
    },
    setExtraCoupon: (index: number, value: Field) => {
    },
    setExtraCoupons: (coupons: Field[]) => {
    },
}

const FormContext = createContext(InitialFormContext);

type Props = {
    children: any,
}
export const FormContextProvider: FC<Props> = ({children}: Props) => {
    const [validated, setValidated] = useState<boolean>(false);
    const [participantExists, setParticipantExists] = useState<boolean>(false);
    const [formExpanded, setFormExpanded] = useState<boolean>(false);
    const [id, setId] = useState<string>('');
    const [email, setEmail] = useState<Field>(InitialFieldValue);
    const [phone, setPhone] = useState<Field>(InitialFieldValue);
    const [firstName, setFirstName] = useState<Field>(InitialFieldValue);
    const [lastName, setLastName] = useState<Field>(InitialFieldValue);
    const [loyaltyCardNumber, setLoyaltyCardNumber] = useState<Field>(InitialFieldValue);
    const [zipCode, setZipCode] = useState<Field>(InitialFieldValue);
    const [receipts, setReceipts] = useState<ReceiptType[]>([EmptyReceipt]);
    const [agreements, setAgreements] = useState<boolean[]>([false]);
    const [prizes, setPrizes] = useState<PrizeType[]>([]);
    const [coupons, setCoupons] = useState<Field[]>([]);
    const [extraCoupons, setExtraCoupons] = useState<Field[]>([]);
    const [extraChances, setExtraChances] = useState<number>(0);
    const [newExtraChances, setNewExtraChances] = useState<number>(0);

    const resetInputs = (except?: string) => {
        except !== PHONE_ID_FIELD && setPhone(InitialFieldValue);
        except !== EMAIL_ID_FIELD && setEmail(InitialFieldValue);
        except !== LOYALTY_CARD_NUMBER_ID_FIELD && setLoyaltyCardNumber(InitialFieldValue);
        setFirstName(InitialFieldValue);
        setLastName(InitialFieldValue);
        setZipCode(InitialFieldValue);
        setReceipts([EmptyReceipt]);
        setCoupons([]);
        setExtraCoupons([]);
        setExtraChances(0);
        setNewExtraChances(0);
        setValidated(false);
        setParticipantExists(false);
        !except && setFormExpanded(false);
    }

    const setParticipant = (p: Participant) => {
        setId(p.id);
        setEmail({value: p.email, error: undefined});
        setPhone({value: p.phone, error: undefined});
        setFirstName({value: p.firstName, error: undefined});
        setLastName({value: p.lastName, error: undefined});
        setLoyaltyCardNumber({value: p.loyaltyCardNumber, error: undefined});
        setZipCode({value: p.zipCode, error: undefined});
        setPrizes(p.prizes ? p.prizes : []);
        setExtraChances(p.extraChances)
        // setReceipts(p.receipts ? p.receipts : []);
    }

    const setReceiptsNumber = (num: number) => {
        setReceipts(Array(num).fill(EmptyReceipt))
    }

    const setReceipt = (index: number, receipt: ReceiptType) => {
        const copy = receipts.map((r: ReceiptType, i: number) => {
            if (i === index) {
                return {...r, ...receipt};
            } else {
                return r;
            }
        });
        setReceipts(copy)
    }

    const setAgreement = (index: number, value: boolean) => {
        const copy = agreements.map((a: boolean, i: number) => {
            if (i === index) {
                return value;
            } else {
                return a;
            }
        });
        setAgreements(copy)
    }

    const setCoupon = (index: number, value: Field) => {
        const copy = coupons.map((c: Field, i: number) => {
            if (i === index) {
                return {...c, ...value};
            } else {
                return c;
            }
        });
        setCoupons(copy)
    }

    const setExtraCoupon = (index: number, value: Field) => {
        const copy = extraCoupons.map((c: Field, i: number) => {
            if (i === index) {
                return {...c, ...value};
            } else {
                return c;
            }
        });
        setExtraCoupons(copy)
    }

    const addPrize = (prize: PrizeType) => {
        setPrizes([...prizes, prize])
    }

    const contextValue: FormContextType = {
        validated,
        participantExists,
        formExpanded,
        id,
        email,
        phone,
        firstName,
        lastName,
        loyaltyCardNumber,
        zipCode,
        receipts,
        agreements,
        prizes,
        coupons,
        extraCoupons,
        extraChances,
        newExtraChances,
        setEmail,
        setPhone,
        setFirstName,
        setLastName,
        setLoyaltyCardNumber,
        setZipCode,
        setParticipantExists,
        setFormExpanded,
        resetInputs,
        setParticipant,
        setReceipt,
        setCoupon,
        setCoupons,
        setReceipts,
        setAgreement,
        setReceiptsNumber,
        setAgreements,
        setValidated,
        addPrize,
        setNewExtraChances,
        setExtraCoupon,
        setExtraCoupons,
    }

    return <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>
}

export default FormContext;
